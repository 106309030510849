import React from "react";
import { Link } from "react-router-dom";
const UnAthorizedMessage = () => {
  return (
    <div className="ui centered container">
      you are not logged-in please <Link to={"/"}>click here</Link> to login
      <br />
      NOTE: if you have already logged in and seeing this please contact us on
      convergint.com
    </div>
  );
};

export default UnAthorizedMessage;
