import ReactBingmaps from "bingmaps-react";
import React from "react";
import { BING_MAP_API_KEY } from "../../constants/apiConstants";
import mapMarker from "../../images/pin.jpg";

const UiMap = (props) => {
  let bbox = [47.6389877, -122.1275374];
  if (Object.keys(props.jsonMapCordsResult).length > 0) {
    console.log(props.jsonMapCordsResult);
    bbox = [];
    props.jsonMapCordsResult.forEach((element) => {
      bbox.push(element[0]);
      bbox.push(element[1]);
    });
  }

  const formatDescriptionStringForPopup = (data) => {
    return data !== undefined && data !== null
      ? `<b>Project-Name:</b> <span style="color:blue">${data.name}
          </span><br/><b>Site:</b> <span style="color:blue">${
            data.site ? data.site : "not provided"
          }</span>
          </span><br/><b>Address:</b> <span style="color:blue">${
            data.address
          }</span>`
      : ``;
  };

  const createInfoBoxWithPushPins = () => {
    let objPushPin = [];
    let data = props.projectData;
    if (props.jsonMapCordsResult.length > 0) {
      let arrCords = props.jsonMapCordsResult;
      for (let i = 0; i < arrCords.length; i++) {
        let temp = arrCords[i];
        let objPinsWithInfo = {
          center: { latitude: temp[0], longitude: temp[1] },
          options: {
            title: `${data.length > 0 ? data[i].name : ""}`,
            color: "navy"
          },
          infobox: {
            closeDelayTime: 100,
            description: formatDescriptionStringForPopup(data[i]),
            title: data.length > 0 ? data[i].name : "",
            zIndex: 1000
          },
          addHandler: {
            type: "click",
            callback: () => {
              alert("click working...");
            }
          }
        };
        objPushPin.push(objPinsWithInfo);
      }
    }
    console.log(objPushPin);
    return objPushPin;
  };

  function GetEventHandled(callbackData) {
    console.log(callbackData);
  }

  const pushPinsWithInfo = createInfoBoxWithPushPins();

  return (
    <ReactBingmaps
      id="four"
      height="500px"
      width="100%"
      bingMapsKey={BING_MAP_API_KEY}
      pushPinsWithInfoboxes={pushPinsWithInfo}
      mapOptions={{
        navigationBarMode: "compact",
        navigationBarOrientation: "horizontal",
        enableHighDpi: true,
        showTermsLink: false
      }}
      viewOptions={{
        center: { latitude: bbox[0], longitude: bbox[1] },
        mapTypeId: "road",
        zoom: 12
      }}
    />
  );
};

export default UiMap;
