import React from "react";
import convergiantLogo from "../../images/convergint-logo-png.png";
const Header = () => {
  return (
    <div className="tittleBar">
      <div className="ui centered large image">
        <img
          src={convergiantLogo}
          alt="company-logo"
          title="converg!nt"
          style={{ marginTop: "8px" }}
        />
      </div>
      <h6>Welcome to the forge!</h6>
    </div>
  );
};

export default Header;
