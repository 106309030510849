import React from "react";
import { Link } from "react-router-dom";
import UserDropdownOptions from "../Buttons/user-dropdown-option";
const Navbar = (props) => {
  return (
    <div>
      <div className="ui secondary pointing menu">
        <Link to="/dashboard" className="item active">
          Folders
        </Link>
        <div className="right menu">
          <UserDropdownOptions username={props.username} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
