import React from "react";
import SignInButton from "../Buttons/SigninButton";
const Home = () => {
  return (
    <div className="ui one column stackable center aligned page grid">
      <div className="nine wide column">
        <div className="ui fluid card center">
          <div className="content">
            <div className="ui large form">
              <div className="field">
                <label>Have an Account ID</label>
                <SignInButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
