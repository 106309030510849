import React from "react";
// import { useNavigate } from "react-router-dom";
// import { UPDATE_PROJECT_ACCESS_PAGE } from "../../constants/routeConstants";

const ProjectInformation = (props) => {
    // const navigate = useNavigate();
    // const navigateToUpdateAccess = (projectNames, isAdmin, selectedProjectId) => {
    //     navigate(UPDATE_PROJECT_ACCESS_PAGE, {
    //         state: {
    //             projectNames: [projectNames],
    //             isAdmin: isAdmin,
    //             selectedProjectId,
    //         }
    //     });
    // };
    const generateRowDataForProject = () => {
        console.log(props.projectData);
        //<tr key={rowData.id} onClick={() => { props.onClickEvent(rowData.id, [rowData.title]) }}>
        let tr = props.projectData.map((rowData) => {
            return (
                <tr key={rowData.key} onClick={() => props.onClickEvent(rowData.key)}>
                    <td>{rowData.title}</td>
                    <td>{rowData.country}</td>
                    <td>{rowData.state_or_province}</td>
                    <td>{rowData.city}</td>
                    <td>{rowData.postal_code}</td>
                    <td>
                        {rowData.address === null
                            ? "-"
                            : `${rowData.address_line_1} ${rowData.address_line_2}`}
                    </td>
                    <td>{rowData.start_date === null ? "-" : rowData.start_date}</td>
                    <td>{rowData.end_date === null ? "-" : rowData.end_date}</td>
                </tr>
            );
        });
        return tr;
    };

    let tableRows = generateRowDataForProject();

    return (
        <table id="projectInfoTable">
            <caption>Project Information</caption>
            <tr>
                <th>Project Name</th>
                <th>Country</th>
                <th>State</th>
                <th>City</th>
                <th>Postal Code</th>
                <th>Address</th>
                <th>Start Date</th>
                <th>End Date</th>
            </tr>
            {tableRows}
        </table>
    );
};

export default ProjectInformation;
