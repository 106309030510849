import React from "react";
import userImg from "../../images/user-profile-img.png";
const UserList = (props) => {
  const { id, name, hasAccess } = props.userlist;
  const itemColor = hasAccess ? "lightgreen" : "#FFCCCC";
  const itemColorOther = !hasAccess ? "#EEFFEE" : "#FFEEEE";

  return (
    <div className="item" style={{ backgroundColor: itemColor }}>
      <div className="right floated content">
        <div className="right floated content">
          <div
            className="ui button"
            style={{
              backgroundColor: itemColorOther,
              marginBottom: "5px",
              marginRight: "5px"
            }}
            onClick={() => props.clickHandler(id, !hasAccess, true)}
          >
            {hasAccess ? "Remove" : "Add"}
          </div>
        </div>
      </div>
      <img
        className="ui avatar image"
        src={userImg}
        alt="user"
        style={{ marginLeft: "5px" }}
      />
      <div className="content">{name}</div>
    </div>
  );
};

export default UserList;
