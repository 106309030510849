import React, { useState } from "react";
import filterImg from "../../images/filter.png";
import deleteImg from "../../images/delete.png";
import fileFilter from "../../images/file-filter.png";
import { useNavigate } from "react-router-dom";
import PreloadDataTree from "../Tree/preloadt-data-tree";
import { DASHBOARD_FOLDER } from "../../constants/page-type-constants";
import {
    SEARCH_ON_MAP_PAGE,
    START_NEW_PROJECT_PAGE,
    UPDATE_PROJECT_ACCESS_PAGE
} from "../../constants/routeConstants";
import ProjectMembersList from "../Lists/project-members-readonly";
import ProjectInformation from "../Table/project-information";
import TempTreeData from "../Tree/temp-tree-data";

const DashboardFolder = (props) => {
    const navigate = useNavigate();
    const [selectedProjectId, setSelectedProjectId] = useState("");
    const [projectNames, setProjectNames] = useState([]);
    const [isAdmin] = useState(props.isAdmin);
    const [searchProjectData, setSearchProjectData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectedProjectKey, setSelectedProjectKey] = useState([]);
    const verticalLineStyle = {
        position: "absolute",
        height: "100%"
    };

    const divContent = {
        marginLeft: "20px"
    };

    const UpdateAccessButton = () => {
        return (
            <button
                className="ui mini blue button"
                onClick={openUpdateAccessPage}
                disabled={selectedProjectId === ""}
            >
                Update Access
            </button>
        );
    };

    const CreateNewProjectButton = () => {
        return (
            <button className="ui small blue button" onClick={openCreateNewProject}>
                Create New Site
            </button>
        );
    };

    const emptyElem = () => <></>;

    const openSearchPageWithMap = () => {
        navigate(SEARCH_ON_MAP_PAGE);
    };
    const openCreateNewProject = () => {
        navigate(START_NEW_PROJECT_PAGE);
    };

    const onProjectClick = (key) => {
        setSelectedProjectKey([key]);
        console.log(key);
    };

    const openUpdateAccessPage = () => {
        navigate(UPDATE_PROJECT_ACCESS_PAGE, {
            state: {
                projectNames: projectNames,
                isAdmin: isAdmin,
                selectedProjectId
            }
        });
    };

    const renderProjectMembersList = (memberList) => {
        let items = memberList.map((item) => {
            return <ProjectMembersList members={item} key={item.id} />;
        });
        return items;
    };

    const getProjectMembersLists = () => {
        let membersList = props.projectMembersList.filter((members) => {
            return members.hasAccess;
        });
        return membersList;
    };

    const onProjectSelect = async (projectId, projectNames) => {
        await props.onProjectSelection(projectId);
        setSelectedProjectId(
            projectId === undefined || projectNames.length === 0 ? "" : projectId
        );
        setProjectNames(projectNames);
        setSearchText("");
    };

    const onSearchTextChange = (event) => {
        setSearchText(event.target.value.trim());
        getSearchedProjects(event.target.value.trim());
    };

    const getSearchedProjects = (query) => {
        let list = [];
        list = props.treeData.filter((item) => {
            return item.title.toLowerCase().includes(query.toLowerCase());
        });
        setSearchProjectData(list);
    };

    let memberList = getProjectMembersLists();
    let projectMemberListItem = renderProjectMembersList(memberList);
    let projectDataInfo = (
        <ProjectInformation
            projectData={
                searchText.length > 0 ? searchProjectData : props.projectData
            }
            //   isAdmin={isAdmin}
            onClickEvent={onProjectClick}
        //onClickEvent={onProjectSelect}
        />
    );

    return (
        <div className="ui internally celled grid">
            <div className="row">
                <div className="three wide column">
                    <h4>Folder View:</h4>
                    <br />
                </div>
                <div className="twelve wide tablet column">
                    {isAdmin ? <CreateNewProjectButton /> : emptyElem}
                    {/* <img
            className="ui mini image"
            style={{ float: "right", marginRight: "5%" }}
            src={fileFilter}
            alt="filter files"
            title="filter-files"
          />
          <img
            className="ui mini image"
            style={{ float: "right", marginRight: "5%" }}
            src={filterImg}
            alt="filter"
            title="filter"
          />
          <img
            className="ui mini image"
            style={{ float: "right", marginRight: "5%" }}
            src={deleteImg}
            alt="delete"
            title="delete"
          /> */}
                    <div
                        className="ui icon right menue input"
                        style={{ float: "right", marginRight: "5%" }}
                    >
                        <input
                            type="text"
                            placeholder="Search Projects"
                            value={searchText}
                            onChange={onSearchTextChange}
                        />
                        <i className="search icon" />
                        <div
                            className="ui mini dropdown blue button"
                            onClick={openSearchPageWithMap}
                        >
                            advanced
                            <i className="dropdown icon" />
                            <div className="menu">
                                <div className="item">Name</div>
                                <div className="item">location</div>
                                <div className="item">region</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{
                overflow: "none",
                height: "50%"
            }}>
                <div className="three wide tablet column" >
                    {isAdmin ? <UpdateAccessButton /> : emptyElem}
                    <br />
                    {/* <PreloadDataTree
            treeData={props.treeData}
            from={DASHBOARD_FOLDER}
            onProjectSelect={onProjectSelect}
            selectedKey={selectedProjectKey}
          /> */}
                    <TempTreeData
                        treeData={props.treeData}
                        from={DASHBOARD_FOLDER}
                        onProjectSelect={onProjectSelect}
                        selectedKey={selectedProjectKey}
                    />
                </div>
                <div className="four wide tablet column">
                    <div
                        className="ui padded segment"
                        style={{
                            overflow: "auto",
                            height: "60%"
                        }}
                    >
                        <div className="ui header">
                            {projectNames.length === 0
                                ? "Please select project to view members"
                                : memberList.length === 0
                                    ? `${projectNames[0]} : (No User Has Access)`
                                    : projectNames[0]}
                        </div>
                        <div className="ui two column very relaxed grid">
                            <div className="ui very relaxed list">
                                {projectMemberListItem}
                            </div>
                        </div>
                    </div>

                </div>
                <div className="nine wide tablet column">
                    <div
                        className="ui padded segment"
                        style={{
                            overflow: "auto",
                            height: "60%"
                        }}
                    >
                        <div style={verticalLineStyle}>
                            <div style={divContent}>
                                {projectNames.length !== 0 || searchText !== "" ? projectDataInfo : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardFolder;
