import React, { useEffect, useState } from "react";
import Header from "./Header/Header";
import Dashboard from "./Dashboard/dashboard";
import AddNewProject from "./Dashboard/add-new-project";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./Home/Home";
import NotFound from "./NotFound/notFound";
import DashboardWithMap from "./Dashboard/dashboard-with-map";
import UpdateProjectAccess from "./Admin/UpdateProjectAccess";
// import projects from "../Assets/data/projectData.json";
// import users from "../Assets/data/users.json";
import {
    DASHBOARD_PAGE,
    SEARCH_ON_MAP_PAGE,
    START_NEW_PROJECT_PAGE,
    UPDATE_PROJECT_ACCESS_PAGE
} from "../constants/routeConstants";
import { API_URI } from "../constants/apiConstants";

function App() {
    const [usersList, setUsersList] = useState([]);
    const [selectedProjectId, setUsersProjectId] = useState("");
    const [projectTreeData, setProjectTreeData] = useState([]);
    const [projectUpdateInitiator, initiateProjectUpdate] = useState(false);
    //const [isAdmin, setIsAdmin] = useState(false);
    const [selectedProjectsInfo, setSelectedProjectsInfo] = useState([]);
    const navigate = useNavigate();

    const fetchUsers = async (projectId) => {
        // let list = projectId !== undefined ? users.users : [];
        let projectInfo =
            projectId !== undefined
                ? projectTreeData.filter((item) => {
                    return item.key === projectId;
                })
                : [];
        // setUsersList(list);
        let json = [];
        if (projectId !== undefined) {
            let response = await fetch(`${API_URI}users/${projectId}`);
            json = await response.json();
        }
        setUsersList(json);
        setSelectedProjectsInfo(projectInfo);
        setUsersProjectId(projectId);
    };

    const addNewProject = async (projectDet = {}) => {
        console.log(projectDet);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(projectDet)
        };
        let response = await fetch(`${API_URI}project/add`, requestOptions);
        let result = await response.json();
        let isSuccess = !("message" in result);
        if (isSuccess) {
            alert("project created successfully");
            navigate(DASHBOARD_PAGE);
            initiateProjectUpdate(!projectUpdateInitiator);
        } else alert("Error occured while creating a project : " + result.message);
    };

    const renderTreeData = (treeData) => {
        // console.log(treeData);
        let tempData = [];
        for (let i = 0; i < treeData.length; i++) {
            if (treeData.children !== null && treeData[i].children.length === 0) {
                tempData.push({
                    ...treeData[i],
                    isLeaf: false
                });
            }
        }
        setProjectTreeData(tempData);
    };

    const fetchProjectTreeData = async () => {
        let response = await fetch(`${API_URI}projects`);
        let json = await response.json();
        renderTreeData(json);
    };

    const getAccessChangedUsersList = () => {
        return usersList
            .filter((users) => {
                return "changedAccess" in users && users.changedAccess;
            })
            .map((users) => {
                return {
                    id: users.id,
                    name: users.name,
                    groups: users.groups,
                    hasAccess: users.hasAccess
                };
            });
    };

    const updateProjectAccess = (id, hasAccess, changedAccess) => {
        console.log(usersList);
        const list = usersList.map((templist) => {
            if (templist.id === id) {
                return {
                    ...templist,
                    hasAccess: hasAccess,
                    changedAccess: changedAccess
                    /*will uncomment or add below commented parameters once we implement access level for users of the project*/
                    // accessType: changedAccess,
                    // latestUpdate: latestUpdate
                };
            }
            return templist;
        });
        setUsersList(list);
    };

    const submitUserAceessChanges = async () => {
        const updatedUsersList = getAccessChangedUsersList();
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedUsersList)
        };
        let response = await fetch(
            `${API_URI}addUsers/${selectedProjectId}`,
            requestOptions
        );
    };

    useEffect(() => {
        (async () => {
            await fetchProjectTreeData();
            console.log("Projects refreshed!");
        })();

        return () => { };
        // setProjectTreeData(projects.projects);
        //setProjectDet(projects.projects);
    }, [projectUpdateInitiator]);

    return (
        <div>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route
                    path={DASHBOARD_PAGE}
                    element={
                        <Dashboard
                            treeDate={projectTreeData}
                            onProjectSelect={fetchUsers}
                            projectMembersList={usersList}
                            projectData={selectedProjectsInfo}
                            fetchProjectTreeData={fetchProjectTreeData}
                        />
                    }
                />
                <Route
                    path={SEARCH_ON_MAP_PAGE}
                    element={<DashboardWithMap projectDet={projectTreeData} />}
                />
                <Route
                    path={START_NEW_PROJECT_PAGE}
                    element={<AddNewProject onSubmit={addNewProject} />}
                />
                <Route
                    path={UPDATE_PROJECT_ACCESS_PAGE}
                    element={
                        <UpdateProjectAccess
                            usersList={usersList}
                            onUpdateProjectAccess={updateProjectAccess}
                            onSubmitUserAccessChanges={submitUserAceessChanges}
                        />
                    }
                />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
}

export default App;
