import React, { useState } from "react";
import Navbar from "../Navbar/navbar";
import PageContainer from "./pageContainer";

const DashboardLayout = (props) => {
  const [user] = useState(
    props.userdetails !== undefined
      ? props.userdetails.name
      : JSON.parse(localStorage.getItem("usercreds")).name
  );
  return (
    <div>
      <Navbar username={user} />
      <PageContainer />
    </div>
  );
};

export default DashboardLayout;
