import { useMsal } from "@azure/msal-react";
import React from "react";
import {
  LOCAL_ISADMIN,
  LOCAL_STORAGE_KEY
} from "../../constants/page-type-constants";

const SignOutButton = () => {
  const { instance } = useMsal();
  const currentAccount = instance.getAccountByHomeId(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)).homeAccountId
  );
  const handleLogout = async () => {
    if (window.confirm("do yo want to sign-out?")) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      localStorage.removeItem(LOCAL_ISADMIN);
      await instance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else return;
  };

  return (
    <div class="item" onClick={() => handleLogout()}>
      sign out
    </div>
  );
};
export default SignOutButton;
