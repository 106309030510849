import React from "react";
import userImg from "../../images/user-profile-img.png";

const ProjectMembersList = (props) => {
  const { id, name, email } = props.members;
  return (
      <div className="item" style={{ overflow: "hidden" }}>
      <img
        className="ui avatar image"
        src={userImg}
        alt="user"
        style={{ marginLeft: "5px" }}
      />
      <div className="content">
        <div className="header">{name}</div>
        <div className="description">
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
    </div>
  );
};

export default ProjectMembersList;
