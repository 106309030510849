import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import DashboardFolder from "./dashboardFolder";
import DashboardLayout from "../Layout/dashboardLayout";
import NotFound from "../NotFound/notFound";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated,
    useMsal
} from "@azure/msal-react";
import UnAthorizedMessage from "../Authenticate-templates/unathorized-user-message";
import usercred from "../../Assets/data/user-creds.json";
import {
    LOCAL_ISADMIN,
    LOCAL_STORAGE_KEY
} from "../../constants/page-type-constants";
import { API_URI } from "../../constants/apiConstants";

const Dashboard = (props) => {
    let isAdminUser =
        localStorage.getItem(LOCAL_ISADMIN) !== null
            ? JSON.parse(localStorage.getItem(LOCAL_ISADMIN)).is_admin_user
            : false;
    console.log("adminAccess: " + isAdminUser);

    let account =
        localStorage.getItem(LOCAL_STORAGE_KEY) !== null
            ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
            : {};

    const onProjectSelection = (projectId) => {
        props.onProjectSelect(projectId);
    };

    return (
        <div>
            <UnauthenticatedTemplate>
                <UnAthorizedMessage />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Routes>
                    <Route path="/" element={<DashboardLayout userdetails={account} />}>
                        <Route
                            index
                            element={
                                <DashboardFolder
                                    treeData={props.treeDate}
                                    userdetails={account}
                                    isAdmin={isAdminUser}
                                    projectMembersList={props.projectMembersList}
                                    onProjectSelection={onProjectSelection}
                                    projectData={props.projectData}
                                />
                            }
                        />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </AuthenticatedTemplate>
        </div>
    );
};

export default Dashboard;
