import React, { useState } from "react";
import SignOutButton from "./SignOut";
import userImage from "../../images/user-profile-img.png";

const UserDropdownOptions = (props) => {
  const [toggleOptions, setToggleOptions] = useState(false);
  const toggle = () => {
    setToggleOptions(!toggleOptions);
  };
  return (
    <div className="ui icon top left pointing dropdown button" onClick={toggle}>
      <div className="text">
        <img
          className="ui avatar image"
          src={userImage}
          alt="user-image"
          title={props.username}
        />
        {props.username}
      </div>
      <i className="dropdown icon" />
      <div className="menue">{toggleOptions ? <SignOutButton /> : <></>}</div>
    </div>
  );
};

export default UserDropdownOptions;
