import React from "react";
import "../../styles/style.css";
import UiMap from "../Map/ui-map";
import { useState } from "react";
import {
  BING_MAP_API_KEY,
  BING_MAP_LOCAL_API_URL_US,
  OUTPUT,
  KEY,
  AND
} from "../../constants/apiConstants";
import { Link } from "react-router-dom";
import { DASHBOARD_PAGE } from "../../constants/routeConstants";

const DashboardWithMap = (props) => {
  const [jsonMapCord, setJsonMapCord] = useState({});
  const [searchedProjectsData, setProjectData] = useState([]);
  const MARGIN_TOP = "marginTop";
  const MARGIN_LEFT = "marginLeft";

  const marginStyle = (type, px) => {
    switch (type) {
      case MARGIN_TOP:
        return { marginTop: px };
      case MARGIN_LEFT:
        return { marginLeft: px };
      default:
        break;
    }
  };

  const getCordinatesOfProject = async (userValue) => {
    let arrProj = props.projectDet !== undefined ? props.projectDet : [];
    let geopoints = [];
    //console.log(arrProj[0].key);
    let tempProjData = [];
    for (let i = 0; i < arrProj.length; i++) {
      if (
        arrProj[i].city.toLowerCase() == userValue.toLowerCase() ||
        arrProj[i].state_or_province.toLowerCase() == userValue.toLowerCase()
      ) {
        let address =
          arrProj[i].address_line_1 + " " + arrProj[i].address_line_2;
          address = address.trim().replace(/[^a-zA-Z0-9 ]/g, "");
        let apiUrl = `${BING_MAP_LOCAL_API_URL_US}/${arrProj[i].state_or_province}/${arrProj[i].postal_code}/${userValue}/${address}${OUTPUT}${AND}${KEY}${BING_MAP_API_KEY}`;
        const response = await fetch(apiUrl);
        const result = await response.json();
        let cords =
          result.resourceSets[0].resources[0].geocodePoints[0].coordinates;
        let data = {
          name: arrProj[i].title,
          address: address,
          site: arrProj[i].site
        };
        tempProjData.push(data);
        geopoints.push(cords);
      }
    }
    setProjectData(tempProjData);
    return geopoints;
  };

  const apires = async () => {
    const searchText = document.getElementById("searchByAddr").value;
    if (searchText.trim() === "") {
      alert("Please enter search value");
      return;
    }
    let coordinates = await getCordinatesOfProject(searchText);
    if (coordinates.length === 0)
      alert(
        "No Project located for given city or state\nSearch value: " +
          searchText
      );
    setJsonMapCord(coordinates);
  };

  let map = (
    <UiMap
      jsonMapCordsResult={jsonMapCord}
      projectData={searchedProjectsData}
    />
  );

  return (
    <div style={{ marginLeft: "50px" }}>
      <div className="mapImage">
        {map === null ? "please wait loading map" : map}
      </div>
      <div>
        <label htmlFor="searchByAddr">SEARCH BY ADDRESS</label>
        <input
          id="searchByAddr"
          name="searchByAddr"
          placeholder="Search By Address"
        />
        {/* <select
          className="ui mini dropdown blue button"
          style={marginStyle(MARGIN_LEFT, "5px")}
        >
          <option value={0}>Search By</option>
          <option value={1}>City</option>
          <option value={2}>State</option>
          <option value={3}>Project ID</option>
        </select> */}
      </div>
      <div style={marginStyle(MARGIN_TOP, "15px")}>
        <label htmlFor="searchBySiteName">SEARCH BY SITE NAME</label>
        <input
          id="searchBySiteName"
          name="searchBySiteNAme"
          placeholder="Search By Site Name"
        />
      </div>
      <div style={marginStyle(MARGIN_TOP, "15px")}>
        <textarea
          className="textArea"
          name="txtAddrWeb"
          id="txtAddrWeb"
          placeholder={" HERE LIST OF ALL SITES WILL APPREAR "}
        />
      </div>
      <div>
        <Link to={DASHBOARD_PAGE}>
          <input
            type="button"
            id="btnManageProject"
            name="btnManageProject"
            value="BACK"
            className="button"
          />
        </Link>

        <input
          type="button"
          id="btnSearchProject"
          name="btnSearchProject"
          value="SEARCH"
          className="button"
          onClick={apires}
          style={marginStyle(MARGIN_LEFT, "5px")}
        />
      </div>
    </div>
  );
};
export default DashboardWithMap;
