import Tree from "rc-tree";
import React, { Component } from "react";
import "rc-tree/assets/index.css";
import { DASHBOARD_FOLDER } from "../../constants/page-type-constants";

class PreloadDataTree extends Component {
    constructor(props) {
        super(props);
        if (props.selectedKey.length > 0) {
            this.onSelect(props.selectedKey);
        }
    }

    state = {
        treeData: this.props.treeData,
        selectedKey: [],
        childNodes: []
    };
    getProjectNames(data, selectedItem) {
        let projNames = [];
        for (let j = 0; j < data.length; j++) {
            if (data[j].key === selectedItem) projNames.push(data[j].title);
        }
        return projNames;
    }

    onSelect = (info) => {
        console.log(info[0]);
        let projectNames =
            this.props.from === DASHBOARD_FOLDER
                ? this.getProjectNames(this.props.treeData, info[0])
                : [];
        this.props.onProjectSelect(info[0], projectNames);
        this.state.selectedKey = [info[0]];
    };

    render() {
        return (
            <div style={{ overflow: "hidden" }}>
                <Tree
                    onSelect={this.onSelect}
                    treeData={this.props.treeData}
                    selectedKeys={
                        this.props.selectedKey.length <= 0
                            ? this.state.selectedKey
                            : this.props.selectedKey
                    }
                />
            </div>
        );
    }
}

export default PreloadDataTree;
