import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div>
      PAGE NOT FOUND GO BACK TO <Link to="/">HOME</Link>
    </div>
  );
};

export default NotFound;
