import React, { useEffect, useState } from "react";

const SearchBox = ({
    onChange,
    placeholderText = "",
    float = "none",
    name,
    onFocus,
    empty
}) => {
    const [val, setVal] = useState("");
    const onChangeQuery = (e) => {
        setVal(e.target.value);
        onChange(e);
    };
    useEffect(() => {
        if (empty)
            onChange(null, name === "ProjectUserSearchBox");
    }, [empty]);
    return (
        <div
            className="ui icon right menue input"
            style={{
                float: float,
                marginRight: "5%",
                float: float
            }}
        >
            <input
                type="text"
                placeholder={placeholderText}
                onChange={onChangeQuery}
                onFocus={() => onFocus(name)}
                value={!empty ? "" : val}
            />
            <i className="search icon" />
        </div>
    );
};

export default SearchBox;
