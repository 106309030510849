import React, { useEffect, useState } from "react";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import { DASHBOARD_FOLDER } from "../../constants/page-type-constants";

const TempTreeData = ({ treeData, from, onProjectSelect, selectedKey }) => {
    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        if (selectedKey.length > 0) onSelect(selectedKey);
    }, [selectedKey]);

    const getProjectNames = (data, selectedItem) => {
        let projNames = [];
        for (let j = 0; j < data.length; j++) {
            if (data[j].key === selectedItem) projNames.push(data[j].title);
        }
        return projNames;
    };

    const onSelect = (info) => {
        console.log(info[0]);
        let projectNames =
            from === DASHBOARD_FOLDER ? getProjectNames(treeData, info[0]) : [];
        onProjectSelect(info[0], projectNames);
        if (selectedKey.length === 0) setSelectedKeys([info[0]]);
    };

    return (
        <div style={{
            overflow: "auto",
            height: "60%",
            margin: "10px"
        }}>
            <Tree
                onSelect={onSelect}
                treeData={treeData}
                selectedKeys={selectedKey.length <= 0 ? selectedKeys : selectedKey}
            />
        </div>
    );
};

export default TempTreeData;
