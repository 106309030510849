import { loginRequest } from "../Login/AzureAuthentication";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_PAGE } from "../../constants/routeConstants";
import { API_URI } from "../../constants/apiConstants";
import { formatDiagnostic } from "typescript";
import {
  LOCAL_ISADMIN,
  LOCAL_STORAGE_KEY
} from "../../constants/page-type-constants";

const SignInButton = () => {
  const [loginresult, setLoginresult] = useState({});
  const { instance } = useMsal();
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const handleLogin = async () => {
    await instance
      .loginPopup(loginRequest)
      .then(async (result) => {
        console.log(result);
        await navigateToDashboard(result.account);
        setLoginresult(result);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const navigateToDashboard = async (result) => {
    //please reuse this for giving options to fetch request
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(result)
    };

    /*tested with my own API working fine you can add your api url
      1) please just change the url on lin 48
      2) send requestOptions as it is to your API (dont change anything on line 49)
      3) please return the result in the object format eg: {isAdmin:<true/false}
      4) replace is_admin_user value with value recieved from api
      5) uncomment the code block line 47 to 51
    */

      const resp = await fetch(
          `${API_URI}isAdmin`,
       requestOptions
     );
     const res = await resp.json();

    if (localStorage.getItem(LOCAL_ISADMIN) === null)
      localStorage.setItem(
        LOCAL_ISADMIN,
          JSON.stringify({ is_admin_user: res.isAdmin }) //replace default true value of is_admin_user with your api result value
      );

    if (localStorage.getItem(LOCAL_STORAGE_KEY) === null);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(result));
    navigate(DASHBOARD_PAGE);
  };

  return (
    <div>
      <button className="ui primary button" onClick={() => handleLogin()}>
        Sign In
      </button>
    </div>
  );
};
export default SignInButton;
