import React from "react";

const AccessDenied = () => {
  return (
    <div className="ui segment">
      <h4>
        You dont have access for this page
        <br />
        NOTE: if you have access initially then your permissions has been
        updated please check this with the admin team thanks
      </h4>
    </div>
  );
};

export default AccessDenied;
