import React, { useEffect } from "react";
import "../../styles/style.css";
import { Link, useNavigate } from "react-router-dom";
import { DASHBOARD_PAGE } from "../../constants/routeConstants";
import country_state from "../../Assets/data/country_state.json";
import { useState } from "react";
import projectTypes from "../../Assets/data/projectType.json";
import CtcLocations from "../../Assets/data/CTCLocations2.json";
import { API_URI } from "../../constants/apiConstants";

const AddNewProject = (props) => {
    const [projectData, setprojectData] = useState([]);
    const navigate = useNavigate();

    let optionsState = null;
    const [optionState, setOptionState] = useState(country_state[0].states);
    const [optionDepts, setOptionDepts] = useState(CtcLocations[0].departments);
    const onSubmit = () => {
        let projectDetails = getProjectData();
        console.log(projectDetails.projectNumber);
        if (
            projectDetails.address_line_1 === "" ||
            projectDetails.city === "" ||
            projectDetails.state_or_province === "" ||
            projectDetails.postal_code === "" ||
            projectDetails.name === ""
        ) {
            alert("Please fill all required fields prefix with '*' (asterisk)");
            return;
        }
        //else if () { }
        props.onSubmit(projectDetails);
    };

    const isNameUsed = (projectName) => {
        return projectData.map((item) => {
            return (
                <option value={item.ConvergintLocation}>
                    {item.ConvergintLocation}
                </option>
            );
        });
    };

    const getOptForCTCLocations = () => {
        /*new JSON*/
        return CtcLocations.map((item) => {
            return <option value={item.value}>{item.displayName}</option>;
        });
        /*/
        return CtcLocations.CTCLocationList.map((item) => {
            return (
                <option value={item.ConvergintLocation}>
                    {item.ConvergintLocation}
                </option>
            );
        });/**/
    };
    const onCTCChange = (event) => {
        for (let i = 0; i < CtcLocations.length; i++) {
            if (CtcLocations[i].value === event.target.value) {
                setOptionDepts(CtcLocations[i].departments);
            }
        }
    };

    const getProjectData = () => {
        let ctcID = document.getElementById("selectCTC");
        let ctc = ctcID.options[ctcID.selectedIndex].value;
        let countryId = document.getElementById("selectCountry");
        let selCountry = countryId.options[countryId.selectedIndex].text;
        let construct_type = document.getElementById("selectConstructionType");
        let seleConstructType =
            construct_type.options[construct_type.selectedIndex].text;
        let deptID = document.getElementById("selectDepartment");
        //let department = deptID.options[deptID.selectedIndex].text;
        let projectType = document.getElementById("selectProjectType");
        let selProjectType = projectType.options[projectType.selectedIndex].text;
        let siteAddr1 = document.getElementById("txtSiteAddress1").value;
        let siteAddr2 = document.getElementById("txtSiteAddress2").value;
        let siteName = document.getElementById("txtSiteName").value;
        let campus = document.getElementById("txtCampusName").value;
        let customerName = document.getElementById("txtCustomerName").value;
        let siteCity = document.getElementById("txtSiteCity").value;
        let siteState = document.getElementById("selectSiteState").value;
        let siteZip = document.getElementById("txtSiteZipCode").value;
        let tempID = document.getElementById("selectTemplate");
        let template = tempID.options[tempID.selectedIndex].text;
        let projectNumber = document.getElementById("txtProjectNumber").value;
        const d = new Date();
        let strtDate =
            d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDay();
        //let projectName = document.getElementById("txtProjectName").value;
        return {
            name: `${ctc}_${campus}_${siteName}`,
            service_types: "field",
            start_date: strtDate,
            end_date: null,
            project_type: selProjectType,
            job_number: `${projectNumber}_${customerName}_${template}`,
            address_line_1: siteAddr1,
            address_line_2: siteAddr2,
            city: siteCity,
            state_or_province: siteState,
            projectNumber,
            postal_code: siteZip,
            country: selCountry,
            value: 0,
            currency: "USD",
            //business_unit_id: "c17e6837-96cd-4839-868e-051a2ad65d28",
            //timezone: "America/New_York",
            language: "en",
            construction_type: seleConstructType,
            //contract_type: "Design-Bid",
            //template_project_id: "fda4ab9e-ab82-4ba9-8d6c-ae7dbd7cee31",
            //include_companies: "true",
            //include_locations: "false"
        };
    };
    const getCountries = () => {
        return country_state.map((item) => {
            return <option value={item.country}>{item.country}</option>;
        });
    };
    const onCountryChange = (event) => {
        for (let i = 0; i < country_state.length; i++) {
            if (country_state[i].country === event.target.value) {
                setOptionState(country_state[i].states);
            }
        }
    };
    const renderProjectTypes = () => {
        let list = projectTypes.projectTypes;
        return list.map((item) => {
            return <option value={item}>{item}</option>;
        });
    };
    let optionsCountry = getCountries();
    let optionProjectTypes = renderProjectTypes();
    let optionsCTCLocations = getOptForCTCLocations();
    return (
        <div className="ui centered container">
            <h2 className="ui dividing center aligned header">Create New Site</h2>
            <div
                className="ui two column very relaxed grid"
                style={{ marginLeft: "1%", marginBottom: "1%" }}
            >
                <div className="column">
                    <div>
                        <label htmlFor="selectCTC">* CTC</label>
                        <select
                            id="selectCTC"
                            name="selectCTC"
                            title="Local CTC"
                            onChange={onCTCChange}
                            required
                            className="selectWidth"
                        >
                            {optionsCTCLocations}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="selectDepartment">* DEPARTMENT</label>
                        <select
                            id="selectDepartment"
                            name="selectDepartment"
                            title="Discipline in the local office responsible for the project"
                            required
                            className="selectWidth"
                        >
                            {optionDepts.map((obj) => {
                                return <option value={obj}>{obj}</option>;
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="txtSiteName">* SITE NAME</label>
                        <input
                            id="txtSiteName"
                            name="txtSiteName"
                            placeholder="Site Name"
                            title="Project name for the site (typically found on the contract drawings)"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="txtCampusName"> CAMPUS NAME</label>
                        <input
                            id="txtCampusName"
                            name="txtCampusName"
                            placeholder="Campus Name"
                            title= "Name of the Campus the site belongs to if applicable (ie: a university campus)"
                        />
                    </div>

                    <div>
                        <label htmlFor="txtCustomerName"> CUSTOMER NAME</label>
                        <input
                            id="txtCustomerName"
                            name="txtCustomerName"
                            placeholder="Customer Name"
                            title= "Name of the building owner or end user"
                        />
                    </div>
                    <div>
                        <label htmlFor="txtProjectNumber">* PROJECT NUMBER</label>
                        <input
                            id="txtProjectNumber"
                            name="txtProjectNumber"
                            placeholder="Project Number"
                            title="Original Convergint Project Number for the initial project"
                            maxLength={30}
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="selectProjectType">* PROJECT TYPE</label>
                        <select
                            id="selectProjectType"
                            name="selectProjectType"
                            title="SELECT PROJECT TYPE"
                            className="selectWidth"
                        >
                            {optionProjectTypes}
                        </select>
                    </div>
                    <div hidden={true}>
                        <label htmlFor="selectTemplate">* TEMPLATE</label>
                        <select
                            id="selectTemplate"
                            name="selectTemplate"
                            title="SELECT FROM GIVEN TEMPLATES"
                            required
                            className="selectWidth"
                        >
                            <option value="1">TEMPLATE1</option>
                            <option value="2">TEMPLATE2</option>
                            <option value="3">TEMPLATE3</option>
                            <option value="4">TEMPLATE4</option>
                        </select>
                    </div>
                </div>
                <div className="column">
                    <div>
                        <label htmlFor="selectConstructionType">* CONSTRUCTION TYPE</label>
                        <select
                            id="selectConstructionType"
                            name="selectConstructionType"
                            title="select Construction Type"
                            className="selectWidth"
                        >
                            <option value="1">New Construction</option>
                            <option value="2">Renovatiom</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="selectCountry">* SITE COUNTRY</label>
                        <select
                            id="selectCountry"
                            name="selectCountry"
                            title="Select Country"
                            onChange={onCountryChange}
                            className="selectWidth"
                        >
                            {optionsCountry}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="selectSiteState">* SITE STATE</label>
                        <select
                            id="selectSiteState"
                            name="txtSiteState"
                            title="Site State"
                            className="selectWidth"
                        >
                            {optionState.map((obj) => {
                                return <option value={obj}>{obj}</option>;
                            })}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="txtSiteAddress1">* ADDRESS LINE 1</label>
                        <input
                            id="txtSiteAddress1"
                            name="txtSiteAddress1"
                            placeholder="Address line 1"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="txtSiteAddress2">ADDRESS LINE 2</label>
                        <input
                            id="txtSiteAddress2"
                            name="txtSiteAddress2"
                            placeholder="Address line 2"
                        />
                    </div>

                    <div>
                        <label htmlFor="txtSiteCity">* SITE CITY</label>
                        <input
                            id="txtSiteCity"
                            name="txtSiteCity"
                            placeholder="Site City"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="txtSiteZipCode">* SITE ZIP CODE</label>
                        <input
                            id="txtSiteZipCode"
                            name="txtSiteZipCode"
                            placeholder="Site Zip Code"
                            required
                        />
                    </div>
                </div>
            </div>
            <div style={{ float: "right" }}>
                <input
                    type="button"
                    id="btnSubmit"
                    value="SUBMIT"
                    className="button"
                    onClick={onSubmit}
                />
                <Link to={DASHBOARD_PAGE}>
                    <button className="button" style={{ marginLeft: "15px" }}>
                        BACK TO DASHBOARD
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default AddNewProject;
