import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AccessDenied from "../../components/Authenticate-templates/access-denied";
import { DASHBOARD_PAGE } from "../../constants/routeConstants";
import UserList from "../Lists/user-list";
import SearchBox from "../SearchBox/searchbox";

const UpdateProjectAccess = (props) => {
    const [searchedList, setSearchedList] = useState([]);
    const [empAccessSearch, setEmpAcessSearch] = useState();
    const [empNoAccessSearch, setEmpNoAcessSearch] = useState();
    const [projectUserSearchText, setProjectUserSearchText] = useState("");
    const [nonProjectUserSearchText, setNonProjectUserSearchText] = useState("");
    const styleCenter = {
        marginLeft: "48%"
    };

    const navigate = useNavigate();
    const location = useLocation();

    const showMessageAndNavigate = () => {
        props.onSubmitUserAccessChanges();
        navigate(DASHBOARD_PAGE);
    };

    const userList = props.usersList;

    const onSearchQueryChange = (event, hasAccess) => {
        let searchText = "";
        if (event == null || event.target == null || event.target.value == null) 
        {
            searchText = hasAccess ? projectUserSearchText : nonProjectUserSearchText;
        }
        else {
            searchText = event.target.value;
            if (hasAccess)
                setProjectUserSearchText(searchText);
            else
                setNonProjectUserSearchText(searchText);
        }
        console.log(`val: ${searchText}\nhasAccess: ${hasAccess}`);
        let query = searchText;
        if (query.trim() === "") {
            setSearchedList([]);
            return;
        }
        let searchedList = userList.filter((item) => {
            return (
                (item.hasAccess === hasAccess &&
                    item.name.toLowerCase().includes(query.toLowerCase())) ||
                item.hasAccess !== hasAccess
            );
        });
        setSearchedList(searchedList);
    };

    const onFocus = (name) => {
        setEmpNoAcessSearch(name === "NonProjectUserSearchBox");
        setEmpAcessSearch(name === "ProjectUserSearchBox");
    };

    const renderList = (type = true) => {
        let tempList = searchedList.length > 0 ? searchedList : userList;
        let list = tempList.filter(function (list) {
            return list.hasAccess === type;
        });
        return list;
    };

    const getList = (type = true) =>
        renderList(type).map((list) => {
            return (
                <UserList
                    userlist={list}
                    clickHandler={updateProjectAccess}
                    key={list.id}
                />
            );
        });

    const updateProjectAccess = (id, hasAccess, changedAccess) => {
        props.onUpdateProjectAccess(id, hasAccess, changedAccess);
        onSearchQueryChange(null, !hasAccess);
        console.log(searchedList);
    };

    let usersWithProjectAccess = getList(true);
    let usersWithNoProjectAccess = getList(false);

    const leftPannelMessage = () => {
        return usersWithNoProjectAccess.length === 0 &&
            usersWithProjectAccess.length > 0
            ? "No data to display"
            : "Loading....";
    };

    const rightPannelMessage = () => {
        return usersWithProjectAccess.length === 0 &&
            usersWithNoProjectAccess.length > 0
            ? "No data to display!"
            : "Loading...";
    };

    if (!location.state.isAdmin) return <AccessDenied />;

    return (
        <div>
            <div>
                <h2>
                    Grant Access For Project :{"  "}
                    <span style={{ color: "blue", fontSize: "20px" }} title="project">
                        [{location.state.projectNames.join(",")}]
                    </span>
                    <Link
                        to={DASHBOARD_PAGE}
                        className="link"
                        style={{ float: "right", fontSize: "18px" }}
                    >
                        Back to Dashboard
                    </Link>
                </h2>
            </div>
            <div className="ui placeholder segment">
                <div className="ui two column very relaxed stackable grid">
                    <div className="column">
                        <div className="ui middle aligned divided list">
                            <h4>Members without access to the project</h4>
                            <SearchBox
                                placeholderText="Search users without access"
                                name="NonProjectUserSearchBox"
                                onChange={(e) => onSearchQueryChange(e, false)}
                                float={"right"}
                                onFocus={onFocus}
                                empty={empNoAccessSearch}
                            />
                            <br></br>
                            <br></br>
                            {usersWithNoProjectAccess.length === 0
                                ? leftPannelMessage()
                                : usersWithNoProjectAccess}
                        </div>
                    </div>
                    <div className="column">
                        <div className="ui middle aligned divided list">
                            <h4>Members with access to the project</h4>
                            <SearchBox
                                placeholderText="Search users with access"
                                name="ProjectUserSearchBox"
                                onChange={(e) => onSearchQueryChange(e, true)}
                                float="right"
                                onFocus={onFocus}
                                empty={empAccessSearch}
                            />
                            <br></br>
                            <br></br>
                            {usersWithProjectAccess.length === 0
                                ? rightPannelMessage()
                                : usersWithProjectAccess}
                        </div>
                    </div>
                </div>
                <div className="ui vertical divider">AND</div>
            </div>
            <div>
                <button
                    className="ui primary button"
                    style={styleCenter}
                    onClick={showMessageAndNavigate}
                >
                    SUBMIT
                </button>
            </div>
        </div>
    );
};

export default UpdateProjectAccess;
